import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Hero from "../components/home/Hero";
import Novel from "../components/home/Novel";
import Chapters from "../components/home/Chapters";

const Main = () => {
  return (
    <main className="min-h-screen" style={{ display: "grid", gridTemplateRows: "auto 1fr auto" }}>
      <Header />
      <div className="flex flex-col gap-6 p-1" style={{ background: "url('/assets/backgrounds/bg.svg') no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}>
        <Hero />
        <Novel />
        <Chapters />
      </div>
      <Footer />
    </main>
  );
};

export default Main;