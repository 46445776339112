export interface Novel {
  description: string;
  frontpage: string;
  name: string;
  tags: string[];
}

export const NovelInfo: Novel[] = [
  {
    "description": "En el misterioso mundo de Aetheris, se alza la antigua ciudad de Zepharia, un lugar de enigmas y secretos olvidados. Fundada por el legendario Aelarian Zepharius, la ciudad es un crisol de culturas, magia y conocimiento ancestral, protegida bajo la mirada del dios Kyros. En el corazón de Zepharia se ocultan poderosos artefactos, portales interdimensionales, y un oscuro castillo que guarda los secretos más profundos de la historia de Aetheris. Mientras los habitantes de Zepharia viven sus vidas entre misteriosos monumentos y peligrosas ruinas, nuevos aventureros llegan a sus costas, atraídos por los rumores de riquezas incalculables y secretos arcanos. Sin embargo, un mal antiguo comienza a despertar, amenazando con desatar un caos que podría consumirlo todo. En medio de la paz aparente, el destino de Zepharia y de Aetheris entero pende de un hilo, esperando ser decidido por aquellos valientes suficientes para desentrañar los misterios de esta tierra legendaria. ¿Podrán los habitantes y nuevos aventureros de Zepharia descubrir la verdad antes de que sea demasiado tarde? ¿O caerán ante las sombras que empiezan a despertar, reclamando lo que una vez fue suyo?",
    "name": "Zepharia",
    "tags": [
      "fantasia",
      "aventura",
      "accion",
      "juegos"
    ],
    "frontpage": "/assets/front/zepharia.jpg"
  }
];