import { useState } from "react";
import { NovelInfo } from "../../constants/novel"

function Novel() {
  const [seeAll, setSeeAll] = useState(false);

  return (
    <>
      {
        NovelInfo.map(novel => (
          <section className="flex justify-center">
            <div className="flex gap-4 p-4 rounded-3xl backdrop-blur-2xl mq-640:flex-col" style={{ border: "2px solid #353535" }}>
              <div className="mq-640:flex mq-640:justify-center">
                <img src={novel.frontpage} alt="Zepharia Novela Web Logo" className="rounded-2xl w-60 min-h-2xl" />
              </div>
              <div className="">
                <h2 className="font-bold text-4xl hover:text-yellow-300 mq-640:text-center">{novel.name}</h2>
                <div className="max-w-2xl mt-2">
                  <div className={`overflow-hidden transition-all duration-300 ease-in-out ${seeAll ? 'max-h-48 overflow-y-auto' : 'max-h-40'}`}>
                    <p className="">{seeAll ? novel.description : `${novel.description.substring(0, 650)}...`}</p>
                  </div>
                  <button onClick={() => setSeeAll(!seeAll)} className="font-semibold">{seeAll ? 'Ver menos' : 'Leer mas'}</button>
                  <div className="flex gap-2 mt-2 flex-wrap">
                    {novel.tags.map(tag => (
                      <div className="bg-zinc-800 w-max rounded-xl cursor-pointer" style={{ padding: "5px 10px" }} title={tag}>
                        <h2 className="text-gray-500">#{tag}</h2>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section >
        ))
      }
    </>
  );
}

export default Novel;