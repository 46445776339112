import { Routes, Route } from "react-router-dom";
import RootLayout from "./layouts/rootLayout";
import Main from "./pages/Main";

import "./global.css";

const App = () => {
  return (
    <>
      <Routes>
        <Route element={<RootLayout />}>
          <Route index element={<Main />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;