function Chapters() {
  return (
    <section className="flex justify-center" id="chapters">
      <div className="flex gap-4 p-4 rounded-3xl backdrop-blur-2xl" style={{ border: "2px solid #353535" }}>
        <h2>Chapters</h2>
      </div>
    </section>
  );
}

export default Chapters;