function Footer() {
  return (
    <section className="mt-2 flex justify-center">
      <div className="p-2 w-full" style={{ maxWidth: "60rem" }}>
        <div className="bg-zinc-600 w-full" style={{ height: ".05rem", maxWidth: "60rem" }}></div>
        <div className="flex justify-between items-center mt-2">
          <div className="mt-1">
            <p>© 2024 SamuraiCraft. Todos los derechos reservados</p>
          </div>
          <div className="flex gap-3" style={{ fontSize: "1.60rem"}}>
            <a href="" title="Discord"><i className="bi bi-discord"></i></a>
            <a href="" title="GitHub"><i className="bi bi-github"></i></a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;