function Header() {
  return (
    <header className="">
      <div className="flex justify-center">
        <a href="/" className="flex">
          <img src="/favicon.png" alt="" className="w-24 h-auto" />
          <span className="text-zinc-700 mt-1 font-bold">v1.2</span>
        </a>
      </div>
    </header>
  );
}

export default Header;