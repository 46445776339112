import { useState } from "react";

function Hero() {
  return (
    <section className="text-center">
      <h1 className="font-bold text-5xl mq-280:text-3xl">SamuraiCraft</h1>
      <p className="mt-2 text-gray-200 w-auto">Lee nuestra novela web, anímate a descubrir mas de nuestra historia adentrándote en un mundo fantasioso.</p>
      <div className="mt-2 flex justify-center items-center">
        <a href="#chapters" className="bg-red-600 rounded-2xl flex gap-2 items-center hover:scale-110 transition-transform duration-300" style={{ padding: "5px 40px" }}>
          <h2 className=" text-zinc-300 text-lg tracking-widest">CAPÍTULOS</h2>
          <i className="bi bi-arrow-down"></i>
        </a>
      </div>
    </section>
  );
}

export default Hero;